<template>
  <div ID="fullscreen" class="wrap">
    <iframe
      ref="iframe"
      :src="htmlSrc"
      width="100%"
      height="100%"
      frameborder="0"
    >
    </iframe>
    <el-row>
      <el-button type="primary" @click="fullScreen()">全屏展示</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      htmlSrc: "23/index.html", // 注意: 直接写 static ,前面不需要加任何路径和字符。
    };
  },
  computed: {},
  watch: {},
  methods: {
    fullScreen() {
      //全屏
      var full = document.getElementById("fullscreen");
      this.launchIntoFullscreen(full);
    },

    //全屏封装
    launchIntoFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    //退出全屏封装
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 1500px;
  height: 750px;
}
</style>
